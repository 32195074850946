<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <div class="control">
              <div class="select"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="value.type"
                  @change="selectType">
                  <option value=""
                    selected>-- Type --</option>
                  <option value="EMAIL">Email</option>
                  <option value="SMS">SMS</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select"
                :class="[`select-${$user.info.theme}`]"
                v-show="value.type === ''">
                <select v-model="value.status">
                  <option value="">-- Status --</option>
                  <option value="SMS_STATE_CODE_DELIVERED">Delivered</option>
                  <option value="SMS_STATE_CODE_EXPIRED">Expired</option>
                  <option value="SMS_STATE_CODE_DELETED">Deleted</option>
                  <option value="SMS_STATE_CODE_UNDELIVERABLE">Undeliverable</option>
                  <option value="SMS_STATE_CODE_INVALID">Invalid</option>
                  <option value="SMS_STATE_CODE_REJECTED">Rejected</option>
                  <option value="SMS_STATE_CODE_SUBMITTED">Submitted</option>
                  <option value="SMS_STATE_CODE_SENT">SMS Sent</option>
                  <option value="SENT">Email Sent</option>
                </select>
              </div>
              <div class="select"
                :class="[`select-${$user.info.theme}`]"
                v-show="value.type === 'EMAIL'">
                <select v-model="value.status">
                  <option value="">-- Status --</option>
                  <option value="SENT">Email Sent</option>
                </select>
              </div>
              <div class="select"
                :class="[`select-${$user.info.theme}`]"
                v-show="value.type === 'SMS'">
                <select v-model="value.status">
                  <option value="">-- Status --</option>
                  <option value="SMS_STATE_CODE_DELIVERED">Delivered</option>
                  <option value="SMS_STATE_CODE_EXPIRED">Expired</option>
                  <option value="SMS_STATE_CODE_DELETED">Deleted</option>
                  <option value="SMS_STATE_CODE_UNDELIVERABLE">Undeliverable</option>
                  <option value="SMS_STATE_CODE_INVALID">Invalid</option>
                  <option value="SMS_STATE_CODE_REJECTED">Rejected</option>
                  <option value="SMS_STATE_CODE_SUBMITTED">Submitted</option>
                  <option value="SMS_STATE_CODE_SENT">SMS Sent</option>
                </select>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'

export default {
  name: 'EmailsmslogListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    value: null
  },
  data() {
    return {
      isFromButtonClassActive: false,
      isToButtonClassActive: false,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {},
  beforeDestroy() {},
  created() {
    if (this.value.dateFrom) {
      this.selectedFromDate = new Date(this.value.dateFrom)
    }
    if (this.value.dateTo) {
      this.selectedToDate = new Date(this.value.dateTo)
    }
  },
  mounted() {},
  methods: {
    onFilterClick() {
      this.value.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.value.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    },
    selectType() {
      this.value.status = ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
